
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ContactForm from '@/components/ContactForm.vue';

    @Component<ViewContact>({
        components: {
            ContactForm,
        },
    })
    export default class ViewContact extends Vue {}
